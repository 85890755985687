<script setup lang="ts">
import type { FaqTypes } from "~/models/Faq";

const { href, type, tab, isActive } = defineProps<{
  tab: { label: string; value: string };
  type?: FaqTypes;
  href?: string;
  isActive: boolean;
}>();

const emit = defineEmits<{
  (e: "click"): void;
  (e: "touchend"): void;
}>();

const color = computed(() => {
  return type === "recruteurs" ? "#37BFDD" : "#FF2C55";
});

const component = computed(() => {
  if (href) return resolveComponent("NuxtLink");
  return "button";
});
</script>

<template>
  <component
    :is="component"
    class="tab-item tabs-nav-content-item"
    :class="{ active: isActive }"
    :to="href"
    @click="emit('click')"
    @touchend="emit('touchend')"
  >
    {{ tab.label }}
  </component>
</template>

<style lang="scss" scoped>
.tab-item {
  text-decoration: none;
  color: #7f868b;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
  padding-top: $gutter;
  line-height: 1.35;
  text-transform: uppercase;
  font-weight: 800;
  @include mq(desktop) {
    margin: 0 var(--showcase-size-100);
  }
  &.active {
    position: relative;
    color: v-bind(color);
    &:after {
      content: "";
      height: 3px;
      width: 100%;
      background-color: v-bind(color);
      position: absolute;
      bottom: -13px;
      left: 0;
      pointer-events: none;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
